import {useSequence} from "./Sequence";
import {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer, TableHead, TableRow,
  TextareaAutosize
} from "@mui/material";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import {sourceState} from "./Full";

const DP = 1;

function displayTimestamp(ts) {
  if (ts === undefined) return undefined;
  const hours = Math.floor(ts / 60 / 60);
  ts -= hours * 60 * 60;
  const minutes = Math.floor(ts / 60);
  ts -= minutes * 60;
  const seconds = Math.floor(ts * DP) / DP;
  return `${hours ? `${hours}:` : ''}${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
}

function isForward(c, i, arr) {
  const before = sourceState(arr.slice(0, i), c.ts);
  return before.seek - before.ts <= c.seek - c.ts;
}

const CommandList = ({seek}) => {
  const {state, dispatch} = useSequence();
  const [json, setJson] = useState(JSON.stringify(state, null, 2));

  function handleChange(event) {
    dispatch({type: 'load', load: JSON.parse(event.target.value)});
    console.log('json', event.target.value);
  }

  useEffect(() => {
    setJson(JSON.stringify(state, null, 2));
  }, [state]);

  return <TableContainer component={Paper}>

    <Table sx={{minWidth: 200}} size="small">
      <TableHead>
        <TableRow>
          <TableCell align="right">Reaction</TableCell>
          <TableCell>Command</TableCell>
          <TableCell align="right">Source</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.commands
          .map((c, i, arr) => <TableRow hover key={c.id} onClick={e => seek?.(c.ts)}>
            <TableCell align="right">{displayTimestamp(c.ts)}</TableCell>
            <TableCell><Chip size="small" icon={
              c.type === 'play' ? <PlayArrowRoundedIcon/> :
                c.type === 'pause' ? <PauseRoundedIcon/> :
                  c.type === 'seek' ? isForward(c, i, arr) ? <FastForwardRoundedIcon/> : <FastRewindRoundedIcon/>
                    : null
            } label={c.type}/></TableCell>
            <TableCell align="right">{displayTimestamp(c.seek)}</TableCell>
            <TableCell><IconButton onClick={e => dispatch({type: 'delete', id: c.id})}
                                   size="small"><DeleteRoundedIcon/></IconButton></TableCell>

          </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
}

export default CommandList;
