import {createContext, useContext, useReducer, useState} from "react";

const SequenceContext = createContext();

export const useSequence = () => {
  const context = useContext(SequenceContext);
  if (!context) throw new Error('Sequence wrapper not found');
  return context;
}

const orderFunc = (a, b) => {
  let cmp = a.ts - b.ts;
  if (cmp) return cmp;
  return a.id - b.id;
}

const Sequence = ({...props}) => {
  const [reactionUrl, setReactionUrl] = useState();
  const [sourceUrl, setSourceUrl] = useState();

  const initialState = {
    reaction: {videoId: null}, source: {
      videoId: null,
      left: 0.5, top: 0.5, width: 0.5, height: 0.5
    }, commands: []
  };

  function reducer(state, {id, ts, type, seek, load, source}) {
    if (load) return load;

    let commands = [...state.commands];
    if (id) {
      const command = commands.find(c => c.id === id);
      if (!command) throw new Error();
      switch (type) {
        case 'play':
        case 'seek':
          command.seek = seek;
        case 'pause':
        case 'resume':
          command.type = type;
          command.ts = ts;
          commands.sort(orderFunc);
          return {...state, commands};
        case 'delete':
          commands = commands.filter(c => c.id !== id);
          return {...state, commands};
        default:
          throw new Error();
      }
    } else {
      const command = {id: Date.now(), type, ts, seek};
      switch (type) {
        case 'source':
          return {...state, source: {...state.source, ...source}};
        case 'resume':
          command.type = 'play';
        case 'pause':
          delete command.seek;
        case 'play':
        case 'seek':
          commands.push(command)
          commands.sort(orderFunc);
          return {...state, commands};
        default:
          throw new Error();
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const context = {
    state, dispatch,
    reactionUrl, setReactionUrl,
    sourceUrl, setSourceUrl
  };

  return <SequenceContext.Provider value={context} {...props} />;
}

export default Sequence;
