import './SelectReaction.css';
import {useSequence} from "./Sequence";
import {Box, Button, Card, CardActions, CardContent, Chip, TextField} from "@mui/material";


function extractVideoId(link) {
  try {
    const url = new URL(link);
    return url.searchParams.get('v');
  } catch (e) {
  }
  return undefined;
}

const SelectReaction = () => {
  const {state, dispatch, reactionUrl, setReactionUrl, sourceUrl, setSourceUrl} = useSequence();

  const reactionId = extractVideoId(reactionUrl);
  const sourceId = extractVideoId(sourceUrl);

  function handleSubmit() {
    if (reactionId && sourceId) {
      const load = {
        reaction: {videoId: reactionId}, source: {
          videoId: sourceId,
          left: 0.5, top: 0, width: 0.5, height: 0.5
        }, commands: []
      };
      dispatch({load});
    }
  }

  return <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >

    <Card variant="outlined">
      <CardContent>
        <h2>Select Reaction and Source videos</h2>
        <TextField fullWidth label="Reaction Video URL" variant="outlined"
                   onChange={e => setReactionUrl(e.target.value)}
                   value={reactionUrl} margin="normal"/>
        <Box m={1} minHeight={50}>{reactionId && <Chip label={reactionId}/>}</Box>
        <div className="link">
          <TextField fullWidth label="Source Video URL" variant="outlined" onChange={e => setSourceUrl(e.target.value)}
                     value={sourceUrl} margin="normal"/>
        </div>
        <Box m={1} minHeight={50}>{sourceId && <Chip label={sourceId}/>}</Box>


      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} disabled={!reactionId || !sourceId} variant="contained">Create Reaction
          Video</Button>
      </CardActions>
    </Card>

  </Box>;
};

export default SelectReaction;
