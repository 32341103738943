import './Full.css';
import {useEffect, useRef, useState} from "react";
import YouTube from "react-youtube";
import {useSequence} from "./Sequence";
import CommandList from "./CommandList";
import FullScreenButton, {toggleFullscreen} from "./FullScreenButton";
import SelectReaction from "./SelectReaction";
import {Box, Button, Card, CardActions, CardContent, Paper, Stack, TextField, ToggleButton} from "@mui/material";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import copy from 'copy-to-clipboard';
import {useResizeDetector} from 'react-resize-detector';


const COMMAND_REDUCER = (state, command, i, arr) => {
  if (command.type === 'play' || command.type === 'resume') {
    // seek
    if ("seek" in command) {
      state.ts = command.ts;
      state.seek = command.seek;
    }
    // resume
    if (!state.play) {
      state.ts = command.ts;
      state.play = 1;
    }
  }
  if (command.type === 'pause') {
    if (state.play) {
      state.seek = state.seek + command.ts - state.ts;
      state.ts = command.ts;
      state.play = 0;
    }
  }
  if (command.type === 'seek') {
    state.ts = command.ts;
    state.seek = command.seek;
  }
  return state;
}

export const sourceState = (commands, now) => {
  let state = {ts: 0, seek: 0, play: 0};
  for (let command of commands) {
    const dt = now - command.ts;
    if (dt < 0) break;
    state = COMMAND_REDUCER(state, command);
  }

  if (state.play) {
    state.seek += now - state.ts;
  }
  state.ts = now;
  state.type = state.play ? 'play' : 'seek';

  return state;
}

const applyCommand = (playing, source, state) => {
  console.log('source.setCurrentTime', state.seek);
  if (state.hasOwnProperty("seek")) source.seekTo(state.seek, true);
  console.log('state', state, playing);
  if (playing) {
    if (state.type === 'play' || state.type === 'resume') {
      source.playVideo();
    }
    if (state.type === 'pause') {
      source.pauseVideo();
    }
  } else {
    source.pauseVideo();
  }
}


const Full = ({edit}) => {
  const {state, dispatch} = useSequence();
  const {reaction: reactionData, source: sourceData, commands} = state;
  const [move, setMove] = useState(false);
  const [reaction, setReaction] = useState();
  const [source, setSource] = useState();
  const [seekCount, setSeekCount] = useState(0);
  const [commandSchedule, setCommandSchedule] = useState([]);
  const [pauseState, setPauseState] = useState({});
  const [offset, setOffset] = useState(0);
  const {width: fullWidth, height: fullHeight, ref: fullRef} = useResizeDetector();
  const sourceBoxRef = useRef();

  useEffect(() => {
    const full = fullRef.current;
    if (!full) return;
    const fullWidth = full.offsetWidth;
    const fullHeight = full.offsetHeight;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const listener = e => {
      const sourcebox = document.querySelector(".sourcebox");
      let sx, sy;
      if (document.fullscreenElement) {
        sx = screenWidth / fullWidth;
        sy = screenHeight / fullHeight;
      } else {
        sx = fullWidth / screenWidth;
        sy = fullHeight / screenHeight;
      }
      sourcebox.style.left = Math.floor(sourcebox.offsetLeft * sx) + "px";
      sourcebox.style.width = Math.floor(sourcebox.offsetWidth * sx) + "px";
      sourcebox.style.top = Math.floor(sourcebox.offsetTop * sy) + "px";
      sourcebox.style.height = Math.floor(sourcebox.offsetHeight * sy) + "px";
    };

    // window.addEventListener('fullscreenchange', listener);
    // return () => window.removeEventListener('fullscreenchange', listener);
  }, []);


  function dragElement(box, handle) {
    let reaction = document.querySelector('.reaction');
    let dx = 0, dy = 0, downX = 0, downY = 0;
    let startW, startH, startX, startY;
    let div = handle ? box.querySelector('.' + handle) : box;
    div.onpointerdown = handleDragStart;

    function handleDragStart(e) {
      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      downX = e.clientX - 0;
      downY = e.clientY - 0;
      startX = box.offsetLeft;
      startY = box.offsetTop;
      startW = box.offsetWidth;
      startH = box.offsetHeight;
      document.onpointerup = handleDragEnd;
      document.onpointermove = handleDrag;
      reaction.style.pointerEvents = 'none'; // restrict mouse events to this frame
      sourceBoxRef.current.classList.add('dragging');
    }

    function handleDrag(e) {
      e = e || window.event;
      e.preventDefault();
      dx = Math.floor(e.clientX - downX);
      dy = Math.floor(e.clientY - downY);

      if (handle) {
        if (handle.includes('e')) {
          let width = Math.floor(startW + dx);
          if (width < 320) width = 320;
          if (startX + width > box.parentElement.offsetWidth) width = box.parentElement.offsetWidth - startX;
          box.style.width = width + "px";
        } else {
          let width = Math.floor(startW - dx);
          if (width < 320) width = 320;
          let left = startX - (width - startW);
          if (left < 0) {
            width += left;
            left = 0;
          }
          box.style.width = width + "px";
          box.style.left = left + "px";
        }

        if (handle?.includes('s')) {
          let height = Math.floor(startH + dy);
          if (height < 240) height = 240;
          if (startY + height > box.parentElement.offsetHeight) height = box.parentElement.offsetHeight - startY;
          box.style.height = height + "px";
        } else {
          let height = Math.floor(startH - dy);
          if (height < 240) height = 240;
          let top = startY - (height - startH);
          if (top < 0) {
            height += top;
            top = 0;
          }
          box.style.height = height + "px";
          box.style.top = top + "px";
        }

      } else {
        let left = Math.floor(startX + dx);
        if (left + startW > box.parentElement.offsetWidth) left = box.parentElement.offsetWidth - startW;
        if (left < 0) left = 0;
        box.style.left = left + "px";

        let top = Math.floor(startY + dy);
        if (top + startH > box.parentElement.offsetHeight) top = box.parentElement.offsetHeight - startH;
        if (top < 0) top = 0;
        box.style.top = top + "px";

      }
    }

    function handleDragEnd() {
      // stop moving when mouse button is released:
      document.onpoinerup = null;
      document.onpointermove = null;
      reaction.style.pointerEvents = 'auto';
      sourceBoxRef.current.classList.remove('dragging');
      const fitWidth = box.parentElement.offsetWidth;
      const fitHeight = box.parentElement.offsetHeight;
      dispatch({
        type: 'source', source: {
          left: parseInt(box.style.left) / fitWidth, top: parseInt(box.style.top) / fitHeight,
          width: parseInt(box.style.width) / fitWidth, height: parseInt(box.style.height) / fitHeight
        }
      })
    }
  }

  useEffect(() => {
    const sourcebox = sourceBoxRef.current;
    if (!sourcebox) return;
    dragElement(sourcebox);
    dragElement(sourcebox, 'nw');
    dragElement(sourcebox, 'ne');
    dragElement(sourcebox, 'sw');
    dragElement(sourcebox, 'se');
  }, [sourceBoxRef.current]);

  function replaceCommandSchedule(schedule) {
    setCommandSchedule(old => {
      old.forEach(clearTimeout);
      return schedule;
    });
  }

  function initCommandSchedule(reaction, source) {
    const ts = reaction.getCurrentTime();
    const schedule = commands
      .filter(c => c.ts >= ts)
      .map(c => setTimeout(() => applyCommand(true, source, c), (c.ts - ts) * 1000));
    replaceCommandSchedule(schedule);
  }

  function clearCommandSchedule() {
    replaceCommandSchedule([]);
  }

  function handleReady(e) {
    console.log('ready', e);
  }

  function handlePlay(e) {
    // console.log('play', e);
    if (pauseState.play) source.playVideo();

    initCommandSchedule(reaction, source);
  }

  function handlePause(e) {
    console.log('pause', e);
    clearCommandSchedule();
    source.pauseVideo();

    // sync test
    console.log('sync test', reaction.getCurrentTime() - source.getCurrentTime());
    setPauseState(sourceState(commands, reaction.getCurrentTime()));

    keepInSync(true);
  }

  function handleEnd(e) {
    console.log('end', e);
    clearCommandSchedule();
    source.seekTo(0);
    source.pauseVideo();
  }

  function handleError(e) {
    console.log('error', e);
  }

  function handleStateChange(e) {
    console.log('stateChange', e);

    if (e.data === 1) {
      initCommandSchedule(reaction, source);
    }
  }

  function handlePlaybackRateChange(e) {
    console.log('playbackRateChange', e);
  }

  function handlePlaybackQualityChange(e) {
    console.log('playbackQualityChange', e);
  }


  // start
  /*
  document.addEventListener('DOMContentLoaded', function () {
    if (window.hideYTActivated) return;
    if (typeof YT === 'undefined') {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    let onYouTubeIframeAPIReadyCallbacks = [];
    for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
      let playerFrame = playerWrap.querySelector("iframe");
      let onPlayerStateChange = function (event) {
        if (event.data == YT.PlayerState.ENDED) {
          playerWrap.classList.add("ended");
        } else if (event.data == YT.PlayerState.PAUSED) {
          playerWrap.classList.add("paused");
        } else if (event.data == YT.PlayerState.PLAYING) {
          playerWrap.classList.remove("ended");
          playerWrap.classList.remove("paused");
        }
      };
      let player;
      onYouTubeIframeAPIReadyCallbacks.push(function () {
        player = new YT.Player(playerFrame, {events: {'onStateChange': onPlayerStateChange}});
      });
      playerWrap.addEventListener("click", function () {
        let playerState = player.getPlayerState();
        if (playerState == YT.PlayerState.ENDED) {
          player.seekTo(0);
        } else if (playerState == YT.PlayerState.PAUSED) {
          player.playVideo();
        }
      });
    }
    window.onYouTubeIframeAPIReady = function () {
      for (let callback of onYouTubeIframeAPIReadyCallbacks) {
        callback();
      }
    };
    window.hideYTActivated = true;
  });
  // end */

  const keepInSync = async force => {
    const now = await reaction.getCurrentTime();
    const state = sourceState(commands, now);

    // always seek if not playing, otherwise allow tolerance
    if ((force || Math.abs(state.seek - await source.getCurrentTime()) > 0.05) && state.seek < source.getDuration()) {
      applyCommand(reaction.state === 1, source, state);
    }
  }

  useEffect(() => {
    // if(reaction?.state === 1) return; // dont do this while playing, remind me why again?
    if (!source || !reaction) return;
    const interval = setTimeout(keepInSync, 500);
    return () => clearTimeout(interval);
  }, [source, reaction, commands, seekCount]);

  useEffect(() => {
    if (!source || !reaction) return;
    const interval = setInterval(() => {
      const now = reaction.getCurrentTime();
      const sourceNow = source.getCurrentTime();
      const lag = now - sourceNow;
      const state = sourceState(commands, now);
      const expectedLag = state.ts - state.seek;
      const expectedOffset = lag - expectedLag;

      if (1 > expectedOffset && expectedOffset > 0.2) {
        reaction.seekTo(now); // causes tiny delay in reaction
      }
      if (-1 < expectedOffset && expectedOffset < -0.2) {
        source.seekTo(sourceNow); // causes tiny delay in source
      }

      if (offset !== expectedOffset) {
        setOffset(expectedOffset);
      }

    }, 500);
    return () => clearInterval(interval);
  }, [source, reaction, commands, seekCount]);

  if (!reactionData?.videoId || !sourceData?.videoId || !commands) return <SelectReaction/>;


  async function addCommand(type) {
    dispatch({type, ts: await reaction.getCurrentTime(), seek: await source.getCurrentTime()});
  }

  const aw = reactionData?.width || 16;
  const ah = reactionData?.height || 9;

  let fitWidth, fitHeight, fitLeft, fitTop;
  if (aw / ah < fullWidth / fullHeight) {
    fitTop = 0;
    fitHeight = fullHeight;
    fitWidth = Math.floor(fitHeight * aw / ah);
    fitLeft = Math.floor((fullWidth - fitWidth) / 2);
  } else {
    fitLeft = 0;
    fitWidth = fullWidth;
    fitHeight = Math.floor(fitWidth * ah / aw);
    fitTop = Math.floor((fullHeight - fitHeight) / 2);
  }

  return <>
    <div style={{display: 'flex', flexDirection: 'horizontal'}}>
      {edit && <Paper>

        <Card elevation={3} m={2}>
          <CardContent>
            <h4>Command List</h4>

            <CommandList seek={ts => {
              reaction?.seekTo(ts);
              source?.seekTo(sourceState(commands, ts).seek);
            }}/>
          </CardContent>
          <CardActions>
            <Stack direction="row" spacing={1}>
              <Button onClick={e => addCommand('play')}><PlayArrowRoundedIcon/> Play</Button>
              <Button onClick={e => addCommand('pause')}><PauseRoundedIcon/> Pause</Button>
              <Button onClick={e => addCommand('resume')}><PlayArrowRoundedIcon/> Resume</Button>
              <Button onClick={e => addCommand('seek')}><FastRewindRoundedIcon/> Seek <FastForwardRoundedIcon/></Button>
            </Stack>
          </CardActions>
        </Card>

        <Card>
          <CardContent>
            <TextField label="sync error (s)" value={offset} aria-readonly disabled fullWidth/>
          </CardContent>
          <CardActions>
            <ToggleButton onClick={e => setMove(!move)} color="primary" value={move}>move</ToggleButton>
            <FullScreenButton/>
            <Button onClick={e => copy(JSON.stringify(state, null, 2))}><ContentCopyRoundedIcon/></Button>
          </CardActions>
        </Card>
      </Paper>}
      <Box
        display="flex"
        justifyContent="center"
        // alignItems="center"
        minHeight="100vh"
        flexGrow="1"
        alignItems="stretch">
        <Box ref={fullRef} className="full" style={{flexGrow: "1"}} m={2}>
          {reaction?.state !== 1 &&
            <Button variant="contained" sx={{bottom: 4, left: 4, position: 'absolute', zIndex: 100000000}} size="small"
                    onClick={() => {
                      const state = sourceState(commands, reaction.getCurrentTime());
                      if (state.play) source.playVideo();
                      reaction.playVideo();
                    }}><PlayArrowRoundedIcon/>x2</Button>}

          {!document.fullscreenElement &&
            <Button variant="contained" sx={{bottom: 4, right: 4, position: 'absolute', zIndex: 100000000}} size="small"
                    onClick={toggleFullscreen}><FullscreenIcon/></Button>}

          {reactionData?.videoId &&
            <YouTube className="reaction focus" videoId={reactionData?.videoId}
                     onReady={e => {
                       handleReady(e);
                       setReaction(e.target);
                     }}
                     onPlay={handlePlay}
                     onPause={handlePause}
                     onEnd={handleEnd}
                     onError={handleError}
                     onStateChange={e => {
                       handleStateChange(e);
                       e.target.state = e.data;
                       setSeekCount(sc => sc + 1);
                     }}
                     onPlaybackRateChange={handlePlaybackRateChange}
                     onPlaybackQualityChange={handlePlaybackQualityChange}
                     opts={{playerVars: {fs: 1, rel: 0, modestbranding: 0, autoplay: edit ? 0 : 1}}}
            />}

          <div style={{
            position: "absolute",
            pointerEvents: 'none',
            width: fitWidth,
            height: fitHeight,
            left: fitLeft,
            top: fitTop
          }}>

            <div ref={sourceBoxRef} style={{
              position: "absolute",
              pointerEvents: 'all',
              left: Math.floor(sourceData.left * fitWidth),
              top: Math.floor(sourceData.top * fitHeight),
              width: Math.floor(sourceData.width * fitWidth),
              height: Math.floor(sourceData.height * fitHeight)
            }}>
              {sourceData?.videoId &&
                <YouTube
                  className={`source focus ${reaction?.state !== 1 ? 'pause' : 'play'} ${edit ? 'edit' : ''} ${move ? ' drag' : ''}`}
                  videoId={sourceData?.videoId}
                  onReady={e => setSource(e.target)}
                  opts={{playerVars: {fs: 0, rel: 0, modestbranding: 1, autoplay: 0, controls: 1}}}/>}

              <div className="nw"></div>
              <div className="ne"></div>
              <div className="sw"></div>
              <div className="se"></div>
              <div className="move"></div>
            </div>

          </div>

        </Box>
      </Box>
    </div>
  </>
    ;
}

export default Full;
