import './App.css';
import Full from "./Full";
import {useSequence} from "./Sequence";
import {useEffect} from "react";

export async function getJson(jsonfile) {
  console.log('getJson', jsonfile);
  const response = await fetch(jsonfile);
  return await response.json();
}

const CONTEXT = "/reaction-player";

const CHANNEL_VIDEO = /c\/([^/]+)\/([^/]+)/;
const EDIT_PATH = `${CONTEXT}/edit`;
const WATCH_PATH = `${CONTEXT}/watch`;

function App() {
  const {state, dispatch} = useSequence();
  let edit = false;
  const url = new URL(window.location.href);

  const regex = CHANNEL_VIDEO;
  const channelVideo = CHANNEL_VIDEO.exec(url.pathname);
  let jsonfile;
  if (channelVideo) {
    const [, channel, videoId] = regex.exec(window.location.href) || [];
    jsonfile = `${CONTEXT}/v/${videoId}.json`;
  }
  if (url.pathname.startsWith(WATCH_PATH)) {
    let v = url.searchParams.get('v') || url.pathname.substring(WATCH_PATH.length + 1);
    if (v) jsonfile = `${CONTEXT}/v/${v}.json`;
    edit = false;
  }
  if (url.pathname.startsWith(EDIT_PATH)) {
    let v = url.searchParams.get('v') || url.pathname.substring(EDIT_PATH.length + 1);
    if (v) jsonfile = `${CONTEXT}/v/${v}.json`;
    else jsonfile = `${CONTEXT}/v/blank.json`;
    edit = true;
  }

  useEffect(() => {
    if (!jsonfile) return;
    (async () => {
      const load = await getJson(jsonfile);
      dispatch({load});
    })();
  }, [jsonfile])

  return (
    <div style={{margin: 'auto'}}>
      <Full edit={edit}/>
    </div>
  );
}

export default App;
