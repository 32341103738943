import {Button} from "@mui/material";

export function toggleFullscreen() {
  let full = document.querySelector('.full');
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    full.requestFullscreen();
  }
}

const FullScreenButton = () => {
  return <Button onClick={toggleFullscreen}>full screen</Button>;
}

export default FullScreenButton;
